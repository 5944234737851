/* eslint-disable */
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import img64 from '@/assets/img/logo_color.json';
import cookieManager from '@/store/cookieManager';

export default {
  name: 'excelManager',
  TYPE_EXP_DOC: 1,
  transformToHuman(item) {
    if (typeof item === 'boolean') return item ? 'Yes' : 'No';
    if (typeof item === 'object' && item instanceof Date) {
      const y = item.getFullYear();
      const m = item.getMonth() + 1; // Months are zero-indexed
      const d = item.getDate();
      let final = `${y}/${m}/${d}`;
      const h = item.getHours();
      const mm = item.getMinutes();
      const s = item.getSeconds();
      if (h === 0 && mm === 0 && s === 0) return final;
      return `${final} ${h}:${mm}:${s}`
    }
    return item;
  },
  async exportExcel(items, columns, reportName, reportType = null) {
    /**
     * CONSTANTS
     */
    const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
    const fileDate = this.transformToHuman(new Date()).replaceAll(':', '').replaceAll('/', '').replaceAll(' ', '_');
    const fileName = `${reportName}_${fileDate}.xlsx`;
    // eslint-disable-next-line
    const options = { filename: fileName, useStyles: true, useSharedStrings: true };

    /**
     * SET KEYS & PREPARE DATA
     */
    let keys = [];
    if (columns === null && items.length) {
      keys = Object.keys(items[0]);
    } else if (columns !== null) {
      keys = columns.map((c) => c.key);
    }
    const copyItems = items.map((it) => {
      const item = {};
      keys.forEach((k) => {
        item[k] = this.transformToHuman(it[k]);
      });
      return item;
    });

    /**
     * GENERATE COLUMNS
     */
    if (columns === null && copyItems && copyItems.length) {
      // eslint-disable-next-line
      columns = [];
      Object.keys(copyItems[0]).forEach((item) => {
        const name = this.capitalize(item.replaceAll('_', ' '));
        // eslint-disable-next-line
        columns.push({ header: name, key: item, width: 30, filterButton: true })
      });
    } else if (columns === null) {
      columns = [];
    }
    let columnAddresses = [];
    let index = 0;
    let preLetter = '';
    let preletterIndex = 0;
    [...new Array(columns.length)].forEach(() => {
      if (index > alphabet.length-1) {
        preLetter = alphabet[preletterIndex]
        preletterIndex += 1;
        index = 0;
      }
      columnAddresses.push(`${preLetter}${alphabet[index]}1`);
      index += 1;
    });

    /**
     * GENERATE WORKBOOK & WORKSHEET
     */
    const workbook = new ExcelJS.Workbook(options);
    const worksheet = workbook.addWorksheet('Page');

    /**
     * ADD & SET COLUMNS
     */
    worksheet.columns = columns;
    worksheet.getRow(1).height = 30;
    columnAddresses.forEach((item) => {
      worksheet.getCell(item).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF6ec377' } };
      worksheet.getCell(item).font = { name: 'Poppins', color: { argb: 'FFFFFFFF' }, family: 2, 'bold': true };
      worksheet.getCell(item).alignment = { vertical: 'middle', horizontal: 'center' };
    });

    /**
     * ADD & SET ITEMS
     */
    copyItems.forEach((item) => {
      worksheet.addRow(item)._cells.forEach((cell) => {
        if (reportType === this.TYPE_EXP_DOC) {
          this.expDocRowCellStyle(worksheet, item, cell);
        } else {
          worksheet.getCell(cell._address).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFf2f2f2' } };
          worksheet.getCell(cell._address).font = { name: 'Poppins', color: { argb: 'FF0a545a' }, family: 2, };
        }
      });
    });

    /**
     * CUSTOMS
     */
    workbook.created = new Date();
    workbook.modified = new Date();
    workbook.lastPrinted = new Date();
    workbook.creator = cookieManager.get('username');
    workbook.lastModifiedBy = cookieManager.get('username');
    if (columnAddresses && columnAddresses.length) {
      worksheet.autoFilter = `${columnAddresses[0].replace('1', '8')}:${columnAddresses.at(-1).replace('1', '8')}`;
    }
    worksheet.spliceRows(1, 0, ...new Array(7));
    worksheet.views = [
      { state: 'frozen', xSplit: 0, ySplit: 8 },
      { showGridLines: false },
    ];

    /**
     * IMAGE - LOGO
     */
    const img = workbook.addImage({ base64: img64.logo, extension: 'png' });
    worksheet.addImage(img, {
      tl: { col: 0.5, row: 0.5 },
      ext: { width: 150, height: 67 },
    });
    const now = new Date();
    const y = now.getFullYear();
    const m = now.getMonth() + 1; // Months are zero-indexed
    const d = now.getDate();
    const h = now.getHours();
    const mm = now.getMinutes();
    const s = now.getSeconds();
    worksheet.getCell(`A${5}`).value = `This document is provided for informational purposes only as of`
    + ` ${d}/${m}/${y} ${h}:${mm}:${s}. The current status of the items must always be verified within the TMF environment.`;
    worksheet.getCell(`A${5}`).font = { name: 'Poppins', color: { argb: 'FF990000' }, family: 2, };

    // const protectOptions = { autoFilter: true, sort: true };
    // await worksheet.protect('password', protectOptions);

    const buf = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buf]), fileName);
  },
  expDocRowCellStyle(worksheet, item, cell) {
    let bgcolor = 'FFA6DAAB';
    if (item.document_count > item.expected_document_count) {
      bgcolor = 'FFF4A8A1';
    } else if (item.document_count < item.expected_document_count) {
      bgcolor = 'FFFFFFB7';
    }
    worksheet.getCell(cell._address).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: bgcolor } };
    worksheet.getCell(cell._address).font = { name: 'Poppins', color: { argb: 'FF0a545a' }, family: 2, };
  },
  capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  },
};
