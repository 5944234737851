import { coreServices } from '@/services/coreServices';

export const milestonesService = {
  getMilestones() {
    return coreServices.get('/milestones/', coreServices.getRequestHeader());
  },
  getMyMilestonesAndStudyExpectedDocuments(studyId) {
    return coreServices.get(
      `/milestones/${studyId}/milestones/study-milestones-and-expected-documents/`,
      coreServices.getRequestHeader(),
    );
  },
  updateMilestones(studyId, data) {
    return coreServices.post(`/milestones/${studyId}/milestones/`, data, coreServices.getRequestHeader());
  },
  updateExpectedDocuments(studyId, data) {
    return coreServices.post(
      `/milestones/${studyId}/update-my-expected-documents/`, data, coreServices.getRequestHeader(),
    );
  },
  closeStudyMilestone(studyId, studyMilestoneId) {
    return coreServices.patch(
      `/milestones/${studyId}/milestones/${studyMilestoneId}/close/`, {}, coreServices.getRequestHeader(),
    );
  },
  openStudyMilestone(studyId, studyMilestoneId) {
    return coreServices.patch(
      `/milestones/${studyId}/milestones/${studyMilestoneId}/open/`, {}, coreServices.getRequestHeader(),
    );
  },
  updateStudyMilestoneStatus(studyId, studyMilestoneId, isClosing) {
    if (isClosing) return this.closeStudyMilestone(studyId, studyMilestoneId);
    return this.openStudyMilestone(studyId, studyMilestoneId);
  },
  getSingleReport(studyId, mileId) {
    return coreServices.get(`/milestones/${studyId}/milestones/${mileId}/report/`, coreServices.getRequestHeader());
  },
  getReport(studyId) {
    return coreServices.get(`/milestones/${studyId}/milestones/report/`, coreServices.getRequestHeader());
  },
};
